import { baseFetch, baseFetchText } from './../common/api';
import { addToast } from './../common/toasts';

import { verifyLoginPromise, loginPromise, logoutPromise } from './../login/loginApi';
import { loadServiceChecksPromise } from './../serviceChecks/serviceChecksApi';

export function verifyLoginCall() {
    return new Promise((resolve, reject) => {
        verifyLoginPromise(resolve, reject)
            .then(response => {
                if (response.status === 401) {
                    resolve({ loggedIn: false });
                } else {
                    resolve({ loggedIn: true });
                }
            });
    });
}

export function loginCall(username, password) {
    return new Promise((resolve, reject) => {
        loginPromise(resolve, reject, username, password)
            .then(response => {
                if (response.status === 401) {
                    resolve({ loggedIn: false });
                } else {
                    resolve({ loggedIn: true });
                }
            });
    });
}

export function logoutCall() {
    return new Promise((resolve, reject) => {
        logoutPromise()
            .then(__text => {
                resolve({});
                setTimeout(() => window.location.reload(), 10)
            })
    });
}