import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { setInputDefaultAction, setCommonExtraReducerCases } from './../common/reducer';

import { loadServiceChecksCall, saveOrUpdateServiceChecksCall, verifyServiceChecksCall, executeServiceChecksCall, loadSingleServiceChecksCall, deleteServiceChecksCall } from './serviceChecksApi';

function emptyInputs(serviceCheck = undefined) {
    return {
        id: serviceCheck === undefined ? '' : serviceCheck.id,
        name: serviceCheck === undefined ? '' : serviceCheck.name,
        delayPeriod: serviceCheck === undefined ? 12 : serviceCheck.delayPeriod,
        connectionTimeout: serviceCheck === undefined ? 30 : serviceCheck.connectionTimeout,
        readTimeout: serviceCheck === undefined ? 30 : serviceCheck.readTimeout,
        retries: serviceCheck === undefined ? 1 : serviceCheck.retries,
        queryUrl: serviceCheck === undefined ? '' : serviceCheck.queryStructure.url,
        queryMethod: serviceCheck === undefined ? 'GET' : serviceCheck.queryStructure.method,
        queryBody: serviceCheck === undefined ? '' : serviceCheck.queryStructure.body,
        queryHeaders: serviceCheck === undefined ? '' : serviceCheck.queryStructure.headers,
    }
}

const initialState = {
  serviceChecks: [],

  inputs: emptyInputs(),

  action: 'list'
};

const initializeAsyncInternalAction = createAsyncThunk(
  'serviceChecks/init',
  async () => {
    return await loadServiceChecksCall();
  }
);

const createServiceChecksAsyncInternalAction = createAsyncThunk(
  'serviceChecks/create',
  async (parameters) => {
    return await saveOrUpdateServiceChecksCall(undefined, { ...parameters });
  }
);

const verifyServiceChecksAsyncInternalAction = createAsyncThunk(
  'serviceChecks/verify',
  async (id) => {
    return await verifyServiceChecksCall(id);
  }
);

const updateServiceChecksAsyncInternalAction = createAsyncThunk(
  'serviceChecks/update',
  async (parameters) => {
    return await saveOrUpdateServiceChecksCall(parameters.id, { ...parameters });
  }
);

const executeServiceChecksAsyncInternalAction = createAsyncThunk(
  'serviceChecks/execute',
  async (parameters) => {
    return await executeServiceChecksCall(parameters.id);
  }
);

const loadServiceChecksAsyncInternalAction = createAsyncThunk(
  'serviceChecks/load',
  async (parameters) => {
    return await loadSingleServiceChecksCall(parameters.id);
  }
);

const deleteServiceChecksAsyncInternalAction = createAsyncThunk(
  'serviceChecks/delete',
  async (parameters) => {
    return await deleteServiceChecksCall(parameters.id);
  }
);

export const serviceChecksSlice = createSlice({
  name: 'serviceChecks',
  initialState,
  reducers: {
    setInputAction: setInputDefaultAction,
    openServiceChecksAddAction: (state, action) => {
      state.action = 'add';
      state.inputs = emptyInputs();
    },
  },
  extraReducers: (builder) => {
    setCommonExtraReducerCases(builder, initializeAsyncInternalAction,
        () => {},
        (state, action) => {
            state.action = 'list';
            state.serviceChecks = action.payload.elements;
        },
        () => {}
    );

    setCommonExtraReducerCases(builder, createServiceChecksAsyncInternalAction,
        () => {},
        (state, action) => {
            state.action = 'list';
            state.serviceChecks = action.payload.elements;
        },
        () => {}
    );

    setCommonExtraReducerCases(builder, verifyServiceChecksAsyncInternalAction,
        () => {},
        (state, action) => {
            alert('Scheduled');
        },
        () => {}
    );

    setCommonExtraReducerCases(builder, updateServiceChecksAsyncInternalAction,
        () => {},
        (state, action) => {
            state.action = 'list';
            state.serviceChecks = action.payload.elements;
        },
        () => {}
    );

    setCommonExtraReducerCases(builder, executeServiceChecksAsyncInternalAction,
        () => {},
        (state, action) => {
            state.action = 'list';
            state.serviceChecks = action.payload.elements;
        },
        () => {}
    );

    setCommonExtraReducerCases(builder, loadServiceChecksAsyncInternalAction,
        () => {},
        (state, action) => {
            state.action = 'edit';
            state.inputs = emptyInputs(action.payload);
        },
        () => {}
    );

    setCommonExtraReducerCases(builder, deleteServiceChecksAsyncInternalAction,
        () => {},
        (state, action) => {
            state.action = 'list';
            state.serviceChecks = action.payload.elements;
        },
        () => {}
    );
  }
});

export const selectServiceChecksState = (state) => state.serviceChecks;

export const { setInputAction, openServiceChecksAddAction } = serviceChecksSlice.actions;

/***********
 * ACTIONS *
 ***********/
export const initializeAction = () => (dispatch, getState) => {
  dispatch(initializeAsyncInternalAction());
};

export const createServiceChecksAction = () => (dispatch, getState) => {
  const serviceChecksState = selectServiceChecksState(getState());

  dispatch(createServiceChecksAsyncInternalAction({
    ...serviceChecksState.inputs
  }));
};

export const verifyServiceChecksAction = () => (dispatch, getState) => {
  const serviceChecksState = selectServiceChecksState(getState());

  dispatch(verifyServiceChecksAsyncInternalAction(serviceChecksState.inputs.id));
};

export const updateServiceChecksAction = () => (dispatch, getState) => {
  const serviceChecksState = selectServiceChecksState(getState());

  dispatch(updateServiceChecksAsyncInternalAction({
    ...serviceChecksState.inputs
  }));
};

export const executeServiceChecksAction = (id) => (dispatch, getState) => {
  dispatch(executeServiceChecksAsyncInternalAction({
    id: id
  }));
};

export const loadServiceChecksAction = (id) => (dispatch, getState) => {
  dispatch(loadServiceChecksAsyncInternalAction({
    id: id
  }));
};

export const deleteServiceChecksAction = (id) => (dispatch, getState) => {
  dispatch(deleteServiceChecksAsyncInternalAction({
    id: id
  }));
};

export default serviceChecksSlice.reducer;