import { baseFetch, baseFetchText } from './../common/api';

export function loadServiceChecksPromise(resolve, reject) {
    return baseFetch(resolve, reject, '/api/admin/service-check');
}

function saveServiceChecksPromise(resolve, reject, requestBody) {
    return baseFetchText(resolve, reject, '/api/admin/service-check', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestBody) });
}

function verifyServiceChecksPromise(resolve, reject, id) {
    return baseFetchText(resolve, reject, `/api/admin/service-check/${id}/verify`, { method: 'POST' });
}

function updateServiceChecksPromise(resolve, reject, id, requestBody) {
    return baseFetchText(resolve, reject, `/api/admin/service-check/${id}`, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestBody) });
}

function executeServiceChecksPromise(resolve, reject, id) {
    return baseFetchText(resolve, reject, `/api/admin/service-check/${id}/execute`, { method: 'PUT' });
}

function loadSingleServiceChecksPromise(resolve, reject, id) {
    return baseFetch(resolve, reject, `/api/admin/service-check/${id}`);
}

function deleteServiceChecksPromise(resolve, reject, id) {
    return baseFetchText(resolve, reject, `/api/admin/service-check/${id}`, { method: 'DELETE' });
}

export function loadServiceChecksCall() {
    return new Promise((resolve, reject) => {
        loadServiceChecksPromise(resolve, reject)
            .then(json => resolve(json))
    });
}

export function saveOrUpdateServiceChecksCall(id, requestBody) {
    delete requestBody.id;

    return new Promise((resolve, reject) => {
        (
            id === undefined
                ? saveServiceChecksPromise(resolve, reject, requestBody)
                : updateServiceChecksPromise(resolve, reject, id, requestBody)
        )
            .then(__text => loadServiceChecksPromise(resolve, reject))
            .then(json => resolve(json))
    });
}

export function verifyServiceChecksCall(id) {
    return new Promise((resolve, reject) => {
        verifyServiceChecksPromise(resolve, reject, id)
            .then(__nothing => resolve({}))
    });
}

export function executeServiceChecksCall(id) {
    return new Promise((resolve, reject) => {
        executeServiceChecksPromise(resolve, reject, id)
            .then(__text => loadServiceChecksPromise(resolve, reject))
            .then(json => resolve(json))
    });
}

export function loadSingleServiceChecksCall(id) {
    return new Promise((resolve, reject) => {
        loadSingleServiceChecksPromise(resolve, reject, id)
            .then(json => resolve(json))
    });
}

export function deleteServiceChecksCall(id) {
    return new Promise((resolve, reject) => {
        deleteServiceChecksPromise(resolve, reject, id)
            .then(__text => loadServiceChecksPromise(resolve, reject))
            .then(json => resolve(json))
    });
}
