export function timestampToString(timestamp) {
    return new Date(timestamp).toLocaleString("en-UK");
}

export function activatePopover() {
    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    popoverTriggerList.map(function (popoverTriggerEl) {
        let opts = {
            animation: false
        }
        if (popoverTriggerEl.hasAttribute('data-bs-content-id')) {
            opts.content = document.getElementById(popoverTriggerEl.getAttribute('data-bs-content-id')).innerHTML;
            opts.html = true;
        }
        return new window.bootstrap.Popover(popoverTriggerEl, opts);
    });
}