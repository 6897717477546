export function addToast(text, type = "warning") {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const toast = document.createElement('div');
    let color;
    if (type === "error") {
        color = "#a71111";
    } else if (type === "warning") {
        color = "#ffc107";
    } else { // info
        color = "#5b9ff3";
    }

    toast.innerHTML = `
        <div class="toast show">
          <div class="toast-header">
            <svg class="bd-placeholder-img rounded me-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false">
              <rect width="100%" height="100%" fill="${color}"></rect>
            </svg>
            <strong class="me-auto">Notification</strong>
            <small class="text-muted">${new Date().toLocaleDateString(navigator.language, options)}</small>
            <button type="button" class="btn-close" data-bs-dismiss="toast"></button>
          </div>
          <div class="toast-body">${text}</div>
        </div>
    `;

    toast.addEventListener('hidden.bs.toast', () => {
        toast.parentNode.removeChild(toast);
    });

    document.getElementById('toastContainer')
        .appendChild(toast);

    new window.bootstrap.Toast(toast, { }).show();
}

export function Toasts() {
    return (
        <div className="bottom-0 end-0">
          <div id="toastContainer" className="toast-container bottom-0 end-0 p-3">
          </div>
        </div>
    )
}