import { addToast } from './toasts';

const host = "https://avi-api-check.space"

const unauthorized = 'HttpStatus.UNAUTHORIZED';
const forbidden = 'HttpStatus.FORBIDDEN';
const badRequest = 'HttpStatus.BAD_REQUEST';

export const verifyLoginPath = '/api/admin/auth/verify-login';
export const requestTokenPath = '/api/admin/auth/request-token';

function getCsrfCookie() {
    const row = document.cookie
        .split('; ')
        .find(row => row.startsWith('XSRF-TOKEN='));

    return row === undefined ? undefined : row.split('=')[1];
}

function getCsrfHeader() {
    const csrfCookie = getCsrfCookie();

    return csrfCookie === undefined ? {} : { 'X-XSRF-TOKEN': csrfCookie };
}

function ApiError(code, jsonPromise) {
    this.code = code;
    this.jsonPromise = jsonPromise;
}

export function baseFetchText(resolve, reject, url, options = {}) {
    return baseFetch(resolve, reject, url, options, response => response.text());
}

export function baseFetch(
        resolve,
        reject,
        url,
        options = {},
        transform = response => response.json(),
        responseHandling = response => {
            const responseStatus = response.status;

            if (responseStatus >= 400) {
                let code;
                if (responseStatus === 401) {
                    code = unauthorized;
                } else if (responseStatus === 403) {
                    code = forbidden;
                } else {
                    code = badRequest;
                }

                throw new ApiError(code, response.json());
            }

            return response;
        }
    ) {

    options.headers = {
        'Accept': 'application/json',
        ...(options.headers || {}),
        ...getCsrfHeader()
    };
    options.redirect = 'error';

    let optionalTokenPromise;
    if (
        url !== verifyLoginPath
        &&
        options.method
        &&
        (
            options.method === 'POST' ||
            options.method === 'PUT' ||
            options.method === 'DELETE'
        )
        &&
        !(options.headers['X-XSRF-TOKEN'])
    ) {
        optionalTokenPromise = fetch(host + requestTokenPath)
            .then(response => {
                options.headers['X-XSRF-TOKEN'] = getCsrfCookie();

                return response.text();
            });
    } else {
        optionalTokenPromise = Promise.resolve();
    }

    return optionalTokenPromise
        .then(__ => fetch(host + url, options))
        .then(response => responseHandling(response))
        .then(response => transform(response))
        .catch(error => {
            const code = error.code;

            if ('Failed to fetch' === code && '/login' === url && 'POST' === options.method) {
                addToast('Unable to login. Please verify credentials or ask for support');
            }
            if ('HttpStatus.UNAUTHORIZED' === code) {
                addToast('Unauthorized');
                setTimeout(() => window.location.reload(), 1000);
            }
            if ('HttpStatus.FORBIDDEN' === code) {
                addToast('Forbidden');
            }
            if ('HttpStatus.BAD_REQUEST' === code) {
                error.jsonPromise.then(json => {
                    const detailedMessage = json.message ? ` (${json.message})` : '';
                    const toastText = `Something went wrong${detailedMessage}. Please recreate use-case and ask for support`;

                    addToast(toastText);
                });
            }

            reject({});
        })

}


