import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { parallelActions } from './../store/store';

import { setCommonExtraReducerCases } from './../common/reducer';

import { selectLoginState, prepareLoginFormAction } from './../login/loginSlice';

import { verifyLoginCall, loginCall, logoutCall } from './mainApi.js';
import {initializeAction} from './../serviceChecks/serviceChecksSlice';

const initialState = {
  spinner: true,

  needLoginCheck: true,
  needLoginCheckInProgress: false,

  loggedIn: false,

  checked: false,
  loaded: false,
  loading: false,

  selectedPage: 'serviceChecks',
};

const verifyLoginAsyncInternalAction = createAsyncThunk(
  'main/verify-login',
  async () => {
    return await verifyLoginCall();
  }
);

const doLoginAsyncInternalAction = createAsyncThunk(
  'main/do-login',
  async (parameters) => {
    return await loginCall(parameters.username, parameters.password);
  }
);

const doLogoutAndVerifyLoginAsyncInternalAction = createAsyncThunk(
  'main/do-logout-and-verify-login',
  async () => {
    return await logoutCall();
  }
);

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    showSpinner: (state) => {
        state.spinner = true
    },
    hideSpinner: (state) => {
        state.spinner = false
    },
    selectPageAction: (state, action) => {
        state.selectedPage = action.payload;
    }
  },
  extraReducers: (builder) => {
    setCommonExtraReducerCases(builder, verifyLoginAsyncInternalAction,
        (state) => {
            state.needLoginCheck = true; // Actually is true before this
            state.needLoginCheckInProgress = true;
        },
        (state, action) => {
            state.needLoginCheck = false;
            state.needLoginCheckInProgress = false;
            state.loggedIn = action.payload.loggedIn;
            if (state.loggedIn) {
                // Hook to trigger initial service checks
                setTimeout(() => { document.getElementById('serviceChecks-init-hook').click(); }, 0);
            }
        },
        () => {}
    );

    setCommonExtraReducerCases(builder, doLoginAsyncInternalAction,
        (state) => {
            state.needLoginCheck = true; // Actually is true before this
            state.needLoginCheckInProgress = true;
        },
        (state, action) => {
            state.needLoginCheck = false;
            state.needLoginCheckInProgress = false;
            state.loggedIn = action.payload.loggedIn;
            if (state.loggedIn) {
                // Hook to trigger initial service checks
                setTimeout(() => { document.getElementById('serviceChecks-init-hook').click(); }, 0);
            }
        },
        () => {}
    );

    setCommonExtraReducerCases(builder, doLogoutAndVerifyLoginAsyncInternalAction,
        (state) => {
            state.needLoginCheck = true; // Actually is true before this
            state.needLoginCheckInProgress = true;
        },
        (state, action) => {
            state.needLoginCheck = false;
            state.needLoginCheckInProgress = false;
            state.loggedIn = action.payload.loggedIn;
        },
        () => {}
    );
  },
});

export const selectMainState = (state) => state.main;

export const { showSpinner, hideSpinner, selectPageAction, transitToNonInitiatedPage } = mainSlice.actions;

export const verifyLoginAction = (forceFlag = false) => (dispatch, getState) => {
  const mainState = selectMainState(getState());

  if (forceFlag || !mainState.needLoginCheckInProgress) {
    dispatch(parallelActions([
        verifyLoginAsyncInternalAction(),
        prepareLoginFormAction()
    ]));
  } else {
      dispatch(prepareLoginFormAction());
  }
};

export const doLoginAction = () => (dispatch, getState) => {
  const loginState = selectLoginState(getState());

  dispatch(parallelActions([
    doLoginAsyncInternalAction({
        username: loginState.inputs.username,
        password: loginState.inputs.password
    }),
    prepareLoginFormAction()
  ]));
};

export const doLogoutAndVerifyLoginAction = () => (dispatch, getState) => {
    dispatch(parallelActions([
        doLogoutAndVerifyLoginAsyncInternalAction(),
        prepareLoginFormAction()
    ]))
};

export default mainSlice.reducer;