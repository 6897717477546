import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { parallelActions } from './../store/store';

import { doLoginAction } from './../main/mainSlice';

import { selectLoginState, setInputAction } from './loginSlice';
import styles from './login.css';

export function Login() {
    const loginState = useSelector(selectLoginState);
    const dispatch = useDispatch();

    const inputs = {
        username: loginState.inputs.username,
        onUsername: e => dispatch(setInputAction({value: e.target.value, input: 'username'})),

        password: loginState.inputs.password,
        onPassword: e => dispatch(setInputAction({value: e.target.value, input: 'password'})),

        onSubmit: (event) => {
            event.preventDefault();

            dispatch(doLoginAction());
        }
    };

    return (
    <div className="form-signin-wrapper">
        <form className="form-signin" onSubmit={inputs.onSubmit}>
            <h1 className="form-signin-heading">Admin panel</h1>
            <p>
                <label htmlFor="username" className="sr-only">Username</label>
                <input type="text" id="username" name="username" className="form-control" placeholder="Enter your login" required autoFocus
                       value={inputs.username} onChange={inputs.onUsername}
                />
            </p>
            <p>
                <label htmlFor="password" className="sr-only">Password</label>
                <input type="password" id="password" name="password" className="form-control" placeholder="Enter your password" required
                       value={inputs.password} onChange={inputs.onPassword}
                />
            </p>
            <button className="btn btn-lg btn-black login-btn" type="submit">Login</button>
        </form>
    </div>
    )
}