import { baseFetch, baseFetchText, verifyLoginPath } from './../common/api';

export function verifyLoginPromise(resolve, reject) {
    return baseFetch(
        resolve,
        reject,
        verifyLoginPath,
        {},
        response => response,
        response => response // No error handling here
    );
}

export function loginPromise(resolve, reject, username, password) {
    return baseFetchText(resolve, reject, '/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          'X-XSRF-TOKEN': document.cookie.split('=')[1]
        },
        body: encodeURIComponent('username') + '=' + encodeURIComponent(username) + '&' + encodeURIComponent('password') + '=' + encodeURIComponent(password)
    });
}

export function logoutPromise(resolve, reject) {
    return baseFetchText(resolve, reject, '/logout', {
        method: 'POST',
        headers: {
          'X-XSRF-TOKEN': document.cookie.split('=')[1]
        }
    });
}
