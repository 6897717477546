import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';

import { store } from './store/store';

import Main from './main/main';

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
      <Provider store={store}>
        <Main />
      </Provider>
  </React.StrictMode>
);
