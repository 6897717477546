import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { setInputDefaultAction, setCommonExtraReducerCases } from './../common/reducer';

function emptyFormInputs() {
    return { username: '', password: '' };
}

const initialState = {
  inputs: emptyFormInputs()
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setInputAction: setInputDefaultAction,
    prepareLoginFormAction: (state, action) => {
      state.inputs = emptyFormInputs();
    }
  },
  extraReducers: (builder) => {
  }
});

export const selectLoginState = (state) => state.login;

export const { setInputAction, prepareLoginFormAction } = loginSlice.actions;

export default loginSlice.reducer;