import styles from './spinner.css';

export function Spinner() {
    return (
        <div>
            <div id="spinnerContainer">
                <div className="spinner-border"></div>
            </div>
            <div id="spinnerBackdrop" className="modal-backdrop fade show"></div>
        </div>
    )
}