import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { parallelActions } from './../store/store';

import { Spinner } from './../common/spinner';
import { Toasts } from './../common/toasts';

import { Login } from './../login/login';
import { prepareLoginFormAction } from './../login/loginSlice';

import { ServiceChecks } from './../serviceChecks/serviceChecks';
import { initializeAction } from './../serviceChecks/serviceChecksSlice';

import {
    selectMainState,
    verifyLoginAction, doLogoutAndVerifyLoginAction
} from './mainSlice';
import styles from './main.css';

function renderContainer(mainState, dispatch) {
    if (mainState.spinner) {
        return (
        <div className="container-fluid">
            <Spinner />
        </div>
        )
    }

    if (!mainState.loggedIn) {
        return (
        <div className="row">
            <div className="col-md-5"></div>
            <div className="col-md-7">
                <div className="full-height d-flex justify-content-start align-items-center">
                    <Login />
                </div>
            </div>
        </div>
        )
    }

    let selectedPageContainer;
    let selectedPageHeader;
    switch (mainState.selectedPage) {
      case 'serviceChecks':
        selectedPageContainer = <ServiceChecks />;
        selectedPageHeader = 'Service Check';
        break;
      default:
        selectedPageContainer = undefined;
        selectedPageHeader = undefined;
    }

    const onLogout = () => dispatch(doLogoutAndVerifyLoginAction());

    if (selectedPageContainer) {
        selectedPageContainer = (
        <div className="col p-3-top">
            <div className="row no-margin">
                <div className="col-md-9">
                    <h1 className="p-3-more-left">{selectedPageHeader}</h1>
                </div>
                <div className="col-md-3 flex-container p-3-more-right">
                    <button className="btn btn-black btn-custom" onClick={onLogout}>Log Out</button>
                </div>
            </div>


            <div className="p-4-no-bottom bg-lightgray">
                <div className="height-128 bg-white">
                    <div className="bg-white">
                        {selectedPageContainer}
                    </div>
                </div>
            </div>
        </div>
        );
    }

    return (
    <div className="row">
        {selectedPageContainer}
    </div>
    )
}

export default function Main() {
    const mainState = useSelector(selectMainState);
    const dispatch = useDispatch();

    if (mainState.needLoginCheck && !mainState.needLoginCheckInProgress) {
        dispatch(verifyLoginAction());
    }

    const onServiceChecksInitHook = () => dispatch(initializeAction());
    let innerContainer = renderContainer(mainState, dispatch);

    return (
    <div className="container-fluid">
        <input id="serviceChecks-init-hook" type="hidden" onClick={onServiceChecksInitHook}/>

        {innerContainer}

        <Toasts />
    </div>
    )
}
