import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { parallelActions } from './../store/store';

import { timestampToString } from './../common/helpers';

import {
    selectServiceChecksState,
    setInputAction, openServiceChecksAddAction,
    initializeAction, createServiceChecksAction, verifyServiceChecksAction, updateServiceChecksAction, executeServiceChecksAction, loadServiceChecksAction, deleteServiceChecksAction
} from './serviceChecksSlice';

import styles from './serviceChecks.css';

function renderServiceChecksTable(dispatch, serviceChecks) {
    const onServiceCheckSelect = id => dispatch(loadServiceChecksAction(id));
    const onServiceCheckDelete = (event, id, name) => {
        if (event.target.className.indexOf('no-click') === -1) {
            if ('buttons' in event && event.buttons === 2) {
                if (window.confirm('Are you sure want to remove ' + name + '?')) {
                    dispatch(deleteServiceChecksAction(id));
                }
            }
        }
    };

    const serviceChecksElements = serviceChecks.map(
        (serviceCheck, index) => <tr key={serviceCheck.id} onMouseDown={event => onServiceCheckDelete(event, serviceCheck.id, serviceCheck.name)}>
            <th onClick={event => onServiceCheckSelect(serviceCheck.id)} className="bg-info" scope="row">{serviceCheck.id}</th>
            <td>{serviceCheck.name}</td>
            <td>{serviceCheck.delayPeriod}</td>
            <td>{serviceCheck.lastOk === 0 ? 'N/A' : timestampToString(serviceCheck.lastOk)}</td>
            <td>{serviceCheck.lastKo === 0 ? 'N/A' : timestampToString(serviceCheck.lastKo)}</td>
        </tr>
    );

    return (
    <table className="table">
        <thead>
            <tr className="bg-border">
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                <th scope="col">Verification period (hours)</th>
                <th scope="col">Last ok</th>
                <th scope="col">Last not ok</th>
            </tr>
        </thead>
        <tbody onContextMenu={e => {e.preventDefault(); return false;}}>
            {serviceChecksElements}
        </tbody>
    </table>
    )
}

function serviceCheckForm(serviceChecksState, dispatch, onSubmit, onVerify) {
    const inputs = {
        name: serviceChecksState.inputs.name,
        onName: e => dispatch(setInputAction({value: e.target.value, input: 'name'})),
        delayPeriod: serviceChecksState.inputs.delayPeriod,
        onDelayPeriod: e => dispatch(setInputAction({value: e.target.value, input: 'delayPeriod'})),
        connectionTimeout: serviceChecksState.inputs.connectionTimeout,
        onConnectionTimeout: e => dispatch(setInputAction({value: e.target.value, input: 'connectionTimeout'})),
        readTimeout: serviceChecksState.inputs.readTimeout,
        onReadTimeout: e => dispatch(setInputAction({value: e.target.value, input: 'readTimeout'})),
        retries: serviceChecksState.inputs.retries,
        onRetries: e => dispatch(setInputAction({value: e.target.value, input: 'retries'})),
        queryUrl: serviceChecksState.inputs.queryUrl,
        onQueryUrl: e => dispatch(setInputAction({value: e.target.value, input: 'queryUrl'})),
        queryMethod: serviceChecksState.inputs.queryMethod,
        onQueryMethod: e => dispatch(setInputAction({value: e.target.value, input: 'queryMethod'})),
        queryBody: serviceChecksState.inputs.queryBody,
        onQueryBody: e => dispatch(setInputAction({value: e.target.value, input: 'queryBody'})),
        queryHeaders: serviceChecksState.inputs.queryHeaders,
        onQueryHeaders: e => dispatch(setInputAction({value: e.target.value, input: 'queryHeaders'})),
        onSubmit: event => { event.preventDefault(); onSubmit(event); },
        onBack: () => dispatch(initializeAction()),
        onVerify: () => dispatch(onVerify())
    }

    return (
    <div className="container">
        <form className="pt-3" onSubmit={inputs.onSubmit}>
            <div className="mb-3">
                <label htmlFor="nameInput" className="form-label">Service name</label>
                <input id="nameInput" className="form-control" type="text" name="name"
                       required min="3"
                       value={inputs.name} onChange={inputs.onName}
                />
            </div>

            <div className="mb-3">
                <label htmlFor="delayPeriodInput" className="form-label">Verification period (hours)</label>
                <input id="delayPeriodInput" className="form-control" type="number" name="delayPeriod"
                       required min="1"
                       value={inputs.delayPeriod} onChange={inputs.onDelayPeriod}
                />
            </div>

            <div className="mb-3">
                <label htmlFor="connectionTimeoutInput" className="form-label">Connection timeout for the request (seconds)</label>
                <input id="connectionTimeoutInput" className="form-control" type="number" name="connectionTimeout"
                       required min="1"
                       value={inputs.connectionTimeout} onChange={inputs.onConnectionTimeout}
                />
            </div>

            <div className="mb-3">
                <label htmlFor="readTimeoutInput" className="form-label">Read timeout for the request (seconds)</label>
                <input id="readTimeoutInput" className="form-control" type="number" name="readTimeout"
                       required min="1"
                       value={inputs.readTimeout} onChange={inputs.onReadTimeout}
                />
            </div>

            <div className="mb-3">
                <label htmlFor="retriesInput" className="form-label">Number of retries on non-successful response</label>
                <input id="retriesInput" className="form-control" type="number" name="retries"
                       required min="1"
                       value={inputs.retries} onChange={inputs.onRetries}
                />
            </div>

            <div className="mb-3 card">
                <div className="card-body">
                    <div className="mb-3">
                        <label htmlFor="queryUrlInput" className="form-label">Query URL</label>
                        <input id="queryUrlInput" className="form-control" type="text" name="queryUrl"
                               required
                               value={inputs.queryUrl} onChange={inputs.onQueryUrl}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="queryMethodInput" className="form-label">Query Method</label>
                        <select id="queryMethodInput" className="form-select" name="queryMethod"
                                value={inputs.queryMethod} onChange={inputs.onQueryMethod}>
                          <option value="GET">GET</option>
                          <option value="POST">POST</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="queryBodyInput" className="form-label">Query Request body</label>
                        <textarea id="queryBodyInput" className="form-control" name="queryBody"
                                  value={inputs.queryBody} onChange={inputs.onQueryBody}
                        ></textarea>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="queryHeadersInput" className="form-label">Query Headers</label>
                        <textarea id="queryHeadersInput" className="form-control" name="queryHeaders"
                                  placeholder="Content-Type: application/json&#10;Authorisation: Bearer token"
                                  value={inputs.queryHeaders} onChange={inputs.onQueryHeaders}
                        ></textarea>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-auto">
                    <button type="submit" className="form-control btn btn-black">Save</button>
                </div>
                <div className="col-auto">
                    <button onClick={inputs.onBack} className="form-control btn btn-black" type="button">Back</button>
                </div>
                <div className="col-auto">
                    <button onClick={inputs.onVerify} className="form-control btn btn-black" type="button">Manual check (background)</button>
                </div>
            </div>
        </form>
    </div>
    )
}

function ServiceChecksAdd() {
    const serviceChecksState = useSelector(selectServiceChecksState);
    const dispatch = useDispatch();

    return serviceCheckForm(
        serviceChecksState, dispatch,
        e => { if (window.confirm('Are you sure?')) { dispatch(createServiceChecksAction()) } }, // onSubmit
        e => {}  // onVerify
    );
}

function ServiceChecksEdit() {
    const serviceChecksState = useSelector(selectServiceChecksState);
    const dispatch = useDispatch();

    return serviceCheckForm(
        serviceChecksState, dispatch,
        e => { if (window.confirm('Are you sure?')) { dispatch(updateServiceChecksAction()) } }, // onSubmit
        e => dispatch(verifyServiceChecksAction()) // onVerify
    );
}

function ServiceChecksList() {
    const serviceChecksState = useSelector(selectServiceChecksState);
    const dispatch = useDispatch();

    const onAddNewServiceCheck = () => dispatch(openServiceChecksAddAction());

    return (
    <div>
        <div className="row mb-3 p-3 justify-content-end">
            <div className="col-auto">
                <button onClick={onAddNewServiceCheck} className="btn btn-black">Add new service check</button>
            </div>
        </div>

        {renderServiceChecksTable(dispatch, serviceChecksState.serviceChecks)}
    </div>
    )
}

export function ServiceChecks() {
    const serviceChecksState = useSelector(selectServiceChecksState);
    const dispatch = useDispatch();

    switch (serviceChecksState.action) {
      case 'add':
        return <ServiceChecksAdd />
      case 'edit':
        return <ServiceChecksEdit />
      case 'list':
      default:
        return <ServiceChecksList />
    }
}