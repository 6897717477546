import { configureStore } from '@reduxjs/toolkit';

import { showSpinner, hideSpinner } from '../main/mainSlice';
import mainReducer from '../main/mainSlice';
import loginReducer from '../login/loginSlice';
import serviceChecksReducer from '../serviceChecks/serviceChecksSlice';

function isMulti(action) {
    return action.type === '_multi';
}

function isPending(action) {
    return action.type && action.type.indexOf('pending') > -1
}

function isFulfilledOrRejected(action) {
    return action.type && (action.type.indexOf('fulfilled') > -1 || action.type.indexOf('rejected') > -1)
}

const multiActionMiddleware = store => next => action => {
  try {
    if (isMulti(action)) {
      return action.actions.map(a => store.dispatch(a));
    }

    if (isPending(action)) {
      return [ store.dispatch(showSpinner()), next(action), ];
    }

    if (isFulfilledOrRejected(action)) {
      return [ next(action), store.dispatch(hideSpinner()) ];
    }

    return next(action);
  } catch (error) {
    console.error('An error occurred: ', error);
  }
};

export function parallelActions(actions, name = '') {
  return {
    type: '_multi',
    actions: actions,
    name: name
  }
}

export const store = configureStore({
  middleware: (getDefaultMiddleware) => {
    const middlewareList = getDefaultMiddleware();

    return middlewareList.length === 1
        ? [multiActionMiddleware, getDefaultMiddleware()[0]]            // PROD
        // First default one, then custom one and last one is thunk
        : [middlewareList[0], multiActionMiddleware, middlewareList[1]] // DEV
  },
  reducer: {
    main: mainReducer,
    login: loginReducer,
    serviceChecks: serviceChecksReducer,
  },
});
